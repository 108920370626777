import React, { FC, useState, createContext, useEffect } from 'react';
import useApiService from 'src/hooks/useApiService';
import { Country } from 'src/models/country';
import { OptionsHttpMethods } from 'src/models/optionsValues';

type CountryContextType = {
  pullerCountries: Country[];
  eventCountries: Country[];
  countryPullerKeyPairs: Record<string, string>;
  countryEventKeyPairs: Record<string, string>;
  refreshCountries: () => Promise<void>;
};

export const CountryContext = createContext<CountryContextType>(
  {} as CountryContextType
);

export const CountryProvider: FC = ({ children }) => {
  const { httpRequest } = useApiService();
  const [pullerCountries, setPullerCountries] = useState<Country[]>([]);
  const [eventCountries, setEventCountries] = useState<Country[]>([]);
  const [countryPullerKeyPairs, setCountryPullerKeyPairs] = useState<Record<string, string>>({});
  const [countryEventKeyPairs, setCountryEventKeyPairs] = useState<Record<string, string>>({});

  useEffect(() => {
    fetchCountriesByPuller();
    fetchCountriesByEvent();
  }, []);

  const fetchCountriesByPuller = async () => {
    const address = `${window.ARM_LIBRARY_ADDRESS}/countries?tags=puller`;
    try {
      const response: Country[] = await httpRequest(OptionsHttpMethods.GET, address);

      // Set countries and create key-pair mapping
      setPullerCountries(response);
      setCountryPullerKeyPairs(
        response.reduce((acc, country) => {
          acc[country.name] = country.code;
          return acc;
        }, {} as Record<string, string>)
      );
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchCountriesByEvent = async () => {
    const address = `${window.ARM_LIBRARY_ADDRESS}/countries?tags=event`;
    try {
      const response: Country[] = await httpRequest(OptionsHttpMethods.GET, address);

      // Set countries and create key-pair mapping
      setEventCountries(response);
      setCountryEventKeyPairs(
        response.reduce((acc, country) => {
          acc[country.name] = country.code;
          return acc;
        }, {} as Record<string, string>)
      );
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const refreshCountries = async () => {
    await fetchCountriesByPuller();
    await fetchCountriesByEvent();
  };

  return (
    <CountryContext.Provider value={{ 
      pullerCountries,
      eventCountries,
      countryPullerKeyPairs,
      countryEventKeyPairs,
      refreshCountries
    }}>
      {children}
    </CountryContext.Provider>
  );
};
