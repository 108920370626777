export enum Arm {
    LEFT = 'left',
    RIGHT = 'right'
}

export enum GenderCategory {
    MEN = 'men',
    WOMEN = 'women'
}

export enum RankingType {
    TIER_LIST_RANKING = 'tierListRanking',
    POINT_BASED_RANKING = 'pointBasedRanking',
    NERD_RANKING = 'nerdRanking'
}

export enum NodeSize {
    XSMALL = 'xtra-small',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}

export enum OptionsHttpMethods {
    POST = 'post',
    PUT = 'put',
    GET = 'get',
    DELETE = 'delete',
    PATCH = 'patch'
}

export enum ScrollDirection {
    UP = "up",
    DOWN = "down"
}