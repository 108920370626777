import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { Box, CssBaseline, Grid } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import Label from './components/Label';
import ReactGA from "react-ga4";
import { useEffect } from 'react';

declare global {
  interface Window {
    ARM_LIBRARY_ADDRESS: string;
    S3_BUCKET_ADDRESS: string;
    WEB_ANALYTICS_TRACKING_ID: string;
  }
}
ReactGA.initialize(window.WEB_ANALYTICS_TRACKING_ID);

function App() {

  useEffect(() => {
    if (window.location.hostname.includes("localhost")) return;
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.document.title });
  }, [window.location.pathname])

  const content = useRoutes(router);
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
          {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
