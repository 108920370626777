import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Authentication from './Authentication';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const ComparePullers = Loader(lazy(() => import('src/content/compare/ComparePullers')));
const Armwrestlers = Loader(lazy(() => import('src/content/armwrestlers/Armwrestlers')));
const AthletePage = Loader(lazy(() => import('src/content/armwrestlers/AthletePage')));
const EventPage = Loader(lazy(() => import('src/content/events/EventPage')));
const Events = Loader(lazy(() => import('src/content/events/Events')));
const Stats = Loader(lazy(() => import('src/content/stats/Stats')));
const AdminDashboard = Loader(lazy(() => import('src/content/dashboards/AdminDashboard')));
const PointBasedPage = Loader(lazy(() => import('src/content/rankings/pointBasedRanking/PointBasedPage')))

// Status
const Status404 = Loader(
  lazy(() => import('src/content/status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '*',
    children: [
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="stats" replace />
      },
      {
        path: 'admin-dashboard',
        children: [
          {
            path: '',
            element: (
            <Authentication>
              <AdminDashboard />
            </Authentication>
            )
          }
        ]
      },
      {
        path: 'armwrestlers',
        element: <Armwrestlers />
      },
      {
        path: 'armwrestlers/:pullerId',
        element: <AthletePage />
      },
      {
        path: 'events',
        element: <Events />
      },
      {
        path: 'events/:eventId',
        element: <EventPage />
      },
      {
        path: 'stats',
        element: <Stats />
      },
      {
        path: 'rankings',
        element: <PointBasedPage />
        // children: [
        //   {
        //     path: '',
        //     element: (<Navigate to="tierListRanking" replace />),
        //   },
        //   {
        //     path: 'tier-list-ranking',
        //     children : [
        //       {
        //         path: '',
        //         element: <TierListRanking />
        //       },
        //       {
        //         path: 'ranking',
        //         element: <TierListPage />
        //       }
        //     ]
        //   },
        //   {
        //     path: 'top-10-ranking',
        //     children : [
        //       {
        //         path: '',
        //         element: <NerdRanking />
        //       },
        //       {
        //         path: 'ranking',
        //         element: <Top10Page />
        //       }
        //     ]
        //   },
        //   {
        //     path: 'point-based',
        //     children : [
        //       {
        //         path: '',
        //         element: <PointBasedPage />
        //       }
        //     ]
        //   },
        //   {
        //     path: 'subscribers',
        //     children: [
        //       {
        //         path:'',
        //         element: <Subscribers />
        //       }
        //     ]
        //   }
        // ]
      },
      {
        path: 'compare',
        element: <ComparePullers />
      },
    ]
  }
];

export default routes;
