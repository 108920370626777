import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { UserSessionProvider } from 'src/contexts/UserSessionContext';
import { UtilsProvider } from './contexts/UtilsContext';
import { CountryProvider } from './contexts/CountryContext';
import { WeightCategoryProvider } from './contexts/WeightCategoryContext';

ReactDOM.render(
  <HelmetProvider>
    <UserSessionProvider>
      <SidebarProvider>
        <CountryProvider>
          <WeightCategoryProvider>
            <UtilsProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </UtilsProvider>
          </WeightCategoryProvider>
        </CountryProvider>
      </SidebarProvider>
    </UserSessionProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
