import { useMediaQuery, useTheme } from '@mui/material';
import { FC, createContext } from 'react';

type UtilsContextType = {
  screenHeight: number;
  screenWidth: number;
  contentHeightLg: number;
  contentHeightMd: number;
  contentHeightSm: number;
  avatarHeightLg: number;
  avatarHeightMd: number;
  avatarHeightSm: number;
  buttonHeightMd: number;
  contentHeightXs: number;
  contentWidthtLg: number;
  contentWidthtMd: number;
  contentWidthtSm: number;
  fakeArray: (x: number) => number[];
};

export const UtilsContext = createContext<UtilsContextType>(
  {} as UtilsContextType
);

export const UtilsProvider: FC = ({ children }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;

  const contentHeightLg = mobile ? window.innerHeight * 0.50 : 400;
  const contentHeightMd = mobile ? window.innerHeight * 0.33 : 350;
  const contentHeightSm = mobile ? window.innerHeight * 0.25 : 300;
  const contentHeightXs = mobile ? window.innerHeight * 0.13 : 250;

  const avatarHeightLg = mobile ? 50 : 70;
  const avatarHeightMd = mobile ? 30 : 50;
  const avatarHeightSm = mobile ? 10 : 30;

  // const buttonHeightLg = mobile ? 50 : 70;
  const buttonHeightMd = mobile ? 38 : 38;
  // const buttonrHeightSm = mobile ? 10 : 30;

  const contentWidthtLg = mobile ? window.innerWidth : 500
  const contentWidthtMd = mobile ? window.innerWidth : 400;
  const contentWidthtSm = mobile ? window.innerWidth : 300;

  const fakeArray = (x) => Array.from({ length: x }, (_, i) => i + 1);

  return (
    <UtilsContext.Provider
      value={{
        screenHeight,
        screenWidth,
        contentHeightLg,
        contentHeightMd,
        contentHeightSm,
        avatarHeightLg,
        avatarHeightMd,
        avatarHeightSm,
        buttonHeightMd,
        contentHeightXs,
        contentWidthtLg,
        contentWidthtMd,
        contentWidthtSm,
        fakeArray
      }}
    >
      {children}
    </UtilsContext.Provider>
  );
};
