import React, { FC, useState, createContext, useEffect } from 'react';
import useApiService from 'src/hooks/useApiService';
import { WeightCategory } from 'src/models/weight-category';
import { OptionsHttpMethods } from 'src/models/optionsValues';

type WeightCategoryContextType = {
  weightCategories: WeightCategory[];
  weightCategoryKeyPairs: Record<string, string>;
  weightDivisionsKeyPairs: Record<string, string>;
  refreshWeightCategories: () => Promise<void>;
};

export const WeightCategoryContext = createContext<WeightCategoryContextType>(
  {} as WeightCategoryContextType
);

export const WeightCategoryProvider: FC = ({ children }) => {
  const { httpRequest } = useApiService();
  const [weightCategories, setWeightCategories] = useState<WeightCategory[]>([]);
  const [weightCategoryKeyPairs, setWeightCategoryKeyPairs] = useState<Record<string, string>>({});
  const [weightDivisionsKeyPairs, setWeightDivisionsKeyPairs] = useState<Record<string, string>>({});


  useEffect(() => {
    fetchWeightCategories();
  }, []);

  const fetchWeightCategories = async () => {
    const address = `${window.ARM_LIBRARY_ADDRESS}/weight-categories`;
    try {
      const response: WeightCategory[] = await httpRequest(OptionsHttpMethods.GET, address);

      // Set weight categories and create key-pair mapping
      setWeightCategories(response);
      setWeightCategoryKeyPairs(
        response.reduce((acc, _entry) => {
          acc[_entry._id] = _entry.shortName;
          return acc;
        }, {} as Record<string, string>)
      );
      setWeightDivisionsKeyPairs(
        response.reduce((acc, _entry) => {
          acc[_entry._id] = _entry.name;
          return acc;
        }, {} as Record<string, string>)
      );
    } catch (error) {
      console.error('Error fetching weight categories:', error);
    }
  };  

  const refreshWeightCategories = async () => {
    await fetchWeightCategories();
  };

  return (
    <WeightCategoryContext.Provider
      value={{ weightCategories, weightCategoryKeyPairs, weightDivisionsKeyPairs, refreshWeightCategories }}
    >
      {children}
    </WeightCategoryContext.Provider>
  );
};
